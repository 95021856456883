import React from 'react';
import styles from './MTGC.module.scss';
import { graphql, useStaticQuery } from 'gatsby';
import {navigate} from 'gatsby-link';
import {
  MTGC_HEADING,
  MTGC_SUBHEADING,
  MTGC_FIFTH_BLOCK_CONTENT,
  MTGC_FIFTH_BLOCK_HEADING,
  MTGC_FIFTH_BLOCK_SUBHEADING,
  MTGC_FIRST_BLOCK_HEADING,
  MTGC_FIRST_BLOCK_TEXT,
  MTGC_FOURTH_BLOCK_CONTENT,
  MTGC_FOURTH_BLOK_HEADING,
  MTGC_SECOND_BLOCK_HEADING,
  MTGC_SECOND_BLOCK_SUBBLOCK,
  MTGC_SECOND_BLOCK_SUBHEADING,
  MTGC_SECOND_BLOCK_TEXT,
  MTGC_THIRD_BLOCK_CONTENT,
  MTGC_THIRD_BLOCK_HEADING,
  MTGC_FIRST_BLOCK_BANNER_BUTTON,
  MTGC_FIRST_BLOCK_BANNER_HEADING,
  MTGC_FIRST_BLOCK_BANNER_TEXT,
  MTGC_TALK_BANNER_HEADING,
  MTGC_TALK_BANNER_SUBHEADING,
  MTGC_INSIGHTS_HEADING, MTGC_CONTACT_US_TEXT
} from '../../constants/migration-to-google-cloud';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Mail from '@material-ui/icons/MailOutlined';
import BulletPointList from '../BulletPointList';
import ExpandingCard from '../ExpandingCard';
import Feed from '../Feed';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import LeaveMessageBanner from '../LeaveMessageBanner';
import SuccessStoriesBanner from '../SuccessStoriesBanner';
import headerStyles from '../Header/Header.module.scss';

const MTGC = () => {
  const {
    background,
    migrationWithCVPicture,
    benefitsPicture,
    servicesIcons,
    gcpEcosystem,
    gcpEcosystemMobile,
    successStoriesBackground,
    insightsPosts
  } = useStaticQuery(graphql`
    query {
      background: allFile(
        filter: {
          sourceInstanceName: { eq: "MTGC" }
          name: { eq: "bg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      migrationWithCVPicture: allFile(
        filter: {
          sourceInstanceName: { eq: "MTGC" }
          name: { eq: "migration-with-cv" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      benefitsPicture: allFile(
        filter: {
          sourceInstanceName: { eq: "MTGC" }
          name: { eq: "benefits" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      servicesIcons: allFile(
        filter: {
          sourceInstanceName: { eq: "MTGC" }
          relativeDirectory: { eq: "services" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      gcpEcosystem: allFile(
        filter: {
          sourceInstanceName: { eq: "MTGC" }
          name: { eq: "gcp-ecosystem" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      gcpEcosystemMobile: allFile(
        filter: {
          sourceInstanceName: { eq: "MTGC" }
          name: { eq: "gcp-ecosystem-mobile" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      successStoriesBackground: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "success-stories-bg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      insightsPosts: allMarkdownRemark(
        filter: {
          fields: {
            slug: {
              in: ["/posts/building-a-modern-digital-enterprise-with-google-cloud-data-fusion", "/posts/google-cloud-migration-strategies-and-opportunities-beyond-usual-cloud-efficiencies", "/posts/10-reasons-why-it-outsourcing-in-ukraine-is-a-perfect-choice-for-global-businesses"]
            }
          }
          frontmatter: {
            template: {
              eq: "post"
            }
            draft: {
              ne: true
            }
          }
        }
        ) {
          nodes {
            fields {
              slug
              categorySlug
              tagSlugs
            }
            frontmatter {
              title
              date
              category
              description
              socialImage
              tags
            }
          }
        }
    }
  `);

  const servicesIconsMap = servicesIcons.nodes.reduce(
    (acc, icon) => ({ ...acc, [icon.name]: icon.publicURL }),
    {}
  );

  const postsEdges = insightsPosts.nodes.map(node => ({node}));

  const setDefaultContactMessage = () => sessionStorage.setItem('contactMessage', MTGC_CONTACT_US_TEXT);

  return (
    <div className={styles['mtgc-page']}>
      <InnerPagesUpperBanner
        bg={background.nodes[0].publicURL}
        className={styles['mtgc-banner']}
        description={[MTGC_SUBHEADING]}
        descriptionClassName={styles['mtgc-banner-description']}
        text={[MTGC_HEADING]}
        textClassName={styles['mtgc-banner-text']}
      />
      <div
        className={`${styles['google-platform']} ${styles['section-container']}`}
      >
        <div className={styles.container}>
          <Typography variant={'h2'} align={'center'} color={'secondary'}>
            {MTGC_FIRST_BLOCK_HEADING}
          </Typography>
          <div className={styles['gc-text']}>
            {MTGC_FIRST_BLOCK_TEXT.map(text => (
              <Typography key={text}>{text}</Typography>
            ))}
          </div>
          <div className={styles['gc-banner']}>
            <div className={styles['gc-banner-text']}>
              <Typography variant="h3">{MTGC_FIRST_BLOCK_BANNER_HEADING}</Typography>
              <p>{MTGC_FIRST_BLOCK_BANNER_TEXT}</p>
            </div>
            <Button
              classes={{root: `${headerStyles['header__contact-us']} ${headerStyles['no-padding-media-query']} ${styles['gc-banner-button']}`}}
              color="primary"
              onClick={() => {
                setDefaultContactMessage();
                navigate('/contact-us');
              }}
              startIcon={<Mail />}
              variant="contained"
            >
              {MTGC_FIRST_BLOCK_BANNER_BUTTON}
            </Button>
          </div>
        </div>
      </div>
      <div
        className={`${styles['gc-migration']} ${styles['section-container']}`}
      >
        <div className={styles.container}>
          <Typography variant={'h2'} align={'center'} color={'secondary'}>
            {MTGC_SECOND_BLOCK_HEADING}
          </Typography>
          <div>
            <div className={styles['image-container']}>
              <img src={migrationWithCVPicture.nodes[0].publicURL} alt="" />
            </div>
            <div className={styles['text-container']}>
              <Typography variant={'h4'}>
                {MTGC_SECOND_BLOCK_SUBHEADING}
              </Typography>
              <Typography>{MTGC_SECOND_BLOCK_TEXT}</Typography>
            </div>
          </div>


          <div className={styles['our-service']}>
            <div className={styles['title-part']}>
              {MTGC_SECOND_BLOCK_SUBBLOCK.heading.map(line => (
                <Typography variant={'h3'} key={line}>
                  {line}
                </Typography>
              ))}
            </div>
            {MTGC_SECOND_BLOCK_SUBBLOCK.content.map((part, ind) => (
              <div key={ind} className={styles['services-part']}>
                <img src={servicesIconsMap[part.icon]} alt="" />
                <Typography className={styles['list-title']}>
                  {part.title}
                </Typography>
                <ul>
                  {part.body.map(item => (
                    <li key={item}>
                      <div className={styles['bullet-container']}>
                        <div className={styles['bullet']}/>
                      </div>
                      <Typography>{item}</Typography>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className={styles['our-service-mobile']}>
            <Typography variant={'h3'} align={'center'}>{MTGC_SECOND_BLOCK_SUBBLOCK.heading.join(' ')}</Typography>
            {MTGC_SECOND_BLOCK_SUBBLOCK.content.map((item, i) => (
              <div className={styles['card-container']} key={i}>
                <ExpandingCard img={servicesIconsMap[item.icon]}
                               smallCard>
                  {{collapsedView: (
                      <>
                        <Typography
                          variant={'h4'}
                          style={{
                            fontSize: '14px',
                            lineHeight: '24px',
                            width: '100%'
                          }}
                        >
                          {item.title}
                        </Typography>
                      </>
                    ),
                    expandedView: (
                      <div className={'expanding-card-container'}>
                        <hr
                          style={{
                            background: '#DFE4ED'
                          }}
                        />
                        <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>
                          <ul>
                            {item.body.filter((item, ind) => ind % 2 === 0).map(item =>  (
                              <li key={item}>
                                <div className={styles['bullet-container']}>
                                  <div className={styles['bullet']}/>
                                </div>
                                <Typography>{item}</Typography>
                              </li>
                            ))}
                          </ul>
                          <ul>
                            {item.body.filter((item, ind) => ind % 2 !== 0).map(item =>  (
                              <li key={item}>
                                <div className={styles['bullet-container']}>
                                  <div className={styles['bullet']}/>
                                </div>
                                <Typography>{item}</Typography>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}}
                </ExpandingCard>
              </div>
            ))}
          </div>

          
        </div>
      </div>
      <SuccessStoriesBanner
        backgroundImage={successStoriesBackground.nodes[0].publicURL}
        beforeNavigate={setDefaultContactMessage}
        className={styles['talk-banner']}
        descriptionClassName={styles['talk-banner-description']}
        heading={MTGC_TALK_BANNER_HEADING}
        subHeading={[MTGC_TALK_BANNER_SUBHEADING]}
        textClassName={styles['talk-banner-text']}
      />
      <div className={`${styles['benefits']} ${styles['section-container']}`}>
        <div className={styles.container}>
          <div>
            <div className={styles['benefits-content']}>
              <Typography variant={'h2'} className={styles['benefits-heading']} color={'secondary'}>
                {MTGC_THIRD_BLOCK_HEADING}
              </Typography>
              <div className={styles['benefits-image-mobile-container']}>
                <img src={benefitsPicture.nodes[0].publicURL} />
              </div>
              <ul className={styles['benefits-list']}>
                {MTGC_THIRD_BLOCK_CONTENT.map((item, index) => {
                  return <li className={styles['benefits-list-item']} key={`benefits-${index}`}>
                    <span className={styles['benefits-list-item-text']}>{item}</span>
                  </li>;
                })}
              </ul>
            </div>
          </div>
          <img className={styles['benefits-image-desktop']} src={benefitsPicture.nodes[0].publicURL} />
        </div>
      </div>
      <div
        className={`${styles['advantage']} ${styles['section-container']}`}
      >
        <div className={styles.container}>
          <Typography variant={'h2'} color={'secondary'} align={'center'}>
            {MTGC_FOURTH_BLOK_HEADING}
          </Typography>
          <div>
            {MTGC_FOURTH_BLOCK_CONTENT.map((list, i) => (
              <ul key={i}>
                {list.map((item, ind) => (
                  <li key={ind}>
                    <div className={styles['bullet-container']}>
                      <div className={styles['bullet']}/>
                    </div>
                    <Typography>{item}</Typography>
                  </li>
                ))}
              </ul>
            ))}
          </div>
          <a href={gcpEcosystem.nodes[0].publicURL} target="_blank">
            <picture>
              <source media="(max-width: 960px)" srcSet={gcpEcosystemMobile.nodes[0].publicURL}/>
              <img src={gcpEcosystem.nodes[0].publicURL} alt="" style={{maxWidth: '828px'}} />
            </picture>
          </a>
        </div>
      </div>
      <div className={styles['cards-block-container']}>
        <div className={styles['cards-block']}>
          <div className={styles['heading-container']}>
            <Typography align="center" variant="h2" color="secondary">
              {MTGC_FIFTH_BLOCK_HEADING}
            </Typography>
            {
              MTGC_FIFTH_BLOCK_SUBHEADING.map((line, i) => (
                <Typography key={i} align={'center'} variant={'h4'}>{line}</Typography>
              ))
            }
          </div>
          <BulletPointList cardWidth={'245px'}
                           cardHeight={'320px'}
                           cards={MTGC_FIFTH_BLOCK_CONTENT}/>
        </div>

      </div>
      <div className={`${styles.insights} ${styles['section-container']}`}>
        <div className={styles.container}>
          <Typography variant={'h2'} color={'secondary'} align={'center'}>
            {MTGC_INSIGHTS_HEADING}
          </Typography>
          <Feed edges={postsEdges} />
        </div>
      </div>
      <LeaveMessageBanner />
    </div>
  );
};

export default MTGC;
